@font-face {
    font-family: 'NotoSansKR', sans-serif;
    src: url('./assets/font/NotoSansKR-VariableFont_wght.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

.body {
    font-family: 'NotoSansKR',sans-serif !important;
}

.ant-modal-close-x {
    font-size: 24px !important;
}

.ant-btn-primary {
    background-color: #6E82FE !important;
    border-color: #6E82FE !important;
    color: white !important;
}
.ant-btn-primary:hover {
    background-color: #1D2F99 !important;
}
.ant-input-prefix {
    display: none !important;
}
.ant-picker-cell-in-view.ant-picker-cell-range-end .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-range-start .ant-picker-cell-inner, .ant-picker-cell-in-view.ant-picker-cell-selected .ant-picker-cell-inner {
    background-color: #6E82FE !important;
}

.ant-picker-cell-in-view.ant-picker-cell-today .ant-picker-cell-inner::before {
    border-color: #6E82FE !important;
}

/*.ant-picker-cell  .ant-picker-cell-inner  .ant-picker-calendar-date-value {*/
/*    color: #ADB5BD !important;*/
/*}*/

.ant-picker-cell-selected.ant-picker-cell-in-view .ant-picker-calendar-date-value
 {
    font-weight: 400 !important;
    color: #fff !important;
}

.ant-picker-cell-inner {
    width: 1.875rem !important;
    height: 1.875rem !important;
    display: inline-flex !important;
    align-items: center !important;
    justify-content: center !important;
    font-size: 0.875rem !important;
}

.ant-table-pagination {
    display: flex !important;
}
.ant-table-pagination {
    display: flex !important;
}

.ant-pagination-total-text {
    position: absolute !important;
    left: 0 !important;
    bottom: -1px !important;
    padding-left: 23px !important;
    font-size: 14px;
    font-family: 'Inter', sans-serif;
    font-weight: 400;
    font-variant-numeric: lining-nums proportional-nums;
}
.ant-pagination-options {
    display: none !important;
}

.ant-notification-notice {
    width: 25rem !important;
}
.ant-form-inline {
    justify-content: center;
}