.videoStream {
    height: 400px;
    width: 700px;
}

.player {
    width: 900px;
    height: 530px;
  }
  
  .player div {
    height: 100%;
  }